import { Buffer } from 'buffer';

import { API } from "../constants";
import {newGet, newPost, generateRandomId, sleep, addToLocalStorage } from "../utils";
import { spotifyPlaylist, spotifyTrackType, spotifyTrackFeaturesType, spotifyAPI } from '../types';
import { getSpotifyPlaylist, saveSpotifyPlaylist } from './firebase';
import aiPlaylistCover from "../assets/ai_playlist_icon.png";

// const aiPlaylistCover = "https://playlistpush.com/blog/content/images/2023/05/data-src-image-dffcac98-cae0-4b80-acee-7a6de5d910c9.png"
export class Spotify implements spotifyAPI {
    token = "";
    constructor() {
        this.getToken();
    }

    async getToken(): Promise<void> {
        const url = API.SPOTIFY_ACCOUNT;
        const body = new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: process.env.REACT_APP_REFRESH_TOKEN?.toString() || "",
            client_id: process.env.REACT_APP_CLIENT_ID?.toString() || ""
        });

        const response = JSON.parse(
            await (await newPost(url, body, {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Basic ' + (Buffer.from(process.env.REACT_APP_CLIENT_ID + ':' + process.env.REACT_APP_CLIENT_SECRET).toString('base64')) 
            })).text() 
            || ""
        );

        // console.log("token >>", response["access_token"])
        this.token = response["access_token"]
    }

    async loadPlaylist(id: string, useCache: boolean = true): Promise<spotifyPlaylist | undefined> {
        let playlist: spotifyPlaylist;

        if (useCache) {
            const cachedPlaylist = localStorage.getItem(id)
            if (cachedPlaylist) return JSON.parse(cachedPlaylist);
            const storedPlaylist = await getSpotifyPlaylist(`spotify:playlist:${id}`);
            if (storedPlaylist) {
                addToLocalStorage(id, JSON.stringify(storedPlaylist));
                return storedPlaylist;
            }
        }

        const url = `${API.SPOTIFY}/playlists/${id}`;

        try {
            const response = JSON.parse(
                await (await newGet(url, {}, {"Authorization": `Bearer ${this.token}`})).text() 
                || ""
            );
            
            if (!response) return;

            playlist = {
                uri: response.uri,
                name: response.name, 
                tracks: await this.loadTracks(id),
                cover: response.images? response.images[0].url : "",
            }
            addToLocalStorage(id, JSON.stringify(playlist));
            const saved = await saveSpotifyPlaylist(playlist.uri, playlist);
            console.log(`[newSpotify][loadPlaylist] (saved) >>`, saved);
            
            console.log(`[newSpotify][loadPlaylist] (playlist) >>`, playlist)
            return playlist;
        } catch (error) {
            console.error(`[newSpotify][loadPlaylist] (error) >>`, error)
        }
    }

    async loadTracks(id: string): Promise<spotifyTrackType[]> {
        let limit = 100;
        const items = [];
        
        for (let i = 0; i < 1000; i += limit) {
            const url = `${API.SPOTIFY}/playlists/${id}/tracks`;
            
            try {
                const response = JSON.parse(
                    await (await newGet(url, {offset: i, limit: limit}, {"Authorization": `Bearer ${this.token}`})).text() 
                    || ""
                );

                items.push(...response.items);
            } catch (error) {
                console.error(`[newSpotify][loadTracks] (error) >>`, error)
            }
        }
        
        const step = 100;
        const n = items.length;
        const tracks: spotifyTrackType[] = [];
        console.log("[newSpotify][loadPlaylist] (n) >>", n)
        
        for (let i = 0; i < n; i += step) {
            const tracksSubSet = items.slice(i, Math.min(i + step, n)).map((item: any) => item.track);
            try {
                // const features: spotifyTrackFeaturesType[] = await this.getTrackFeatures(tracksSubSet) || [];
                const features: spotifyTrackFeaturesType[] = this.getDummyTrackFeatures(tracksSubSet);

                if (features.length !== tracksSubSet.length) continue
                for (let j = 0; j < Math.min(step, n - i); j++) {
                    tracks.push({
                        uri: tracksSubSet[j].uri,
                        name: tracksSubSet[j].name,
                        explicit: tracksSubSet[j].explicit,
                        duration: tracksSubSet[j].duration_ms,
                        cover: tracksSubSet[j].album.images[0].url,
                        availableMarkets: tracksSubSet[j].available_markets,
                        artists: tracksSubSet[j].artists.map((artist: any) => artist.name),
    
                        features: features[j]
                    })
                }
            } catch (error) {
                console.error(`[newSpotify][loadTracks] (error) >>`, error)
            }
        }

        return tracks;
    }

    async getTrackFeatures(tracks: any[]): Promise<spotifyTrackFeaturesType[] | undefined> {
        let url = `${API.SPOTIFY}/audio-features/`;
        const body = {ids: `${tracks?.map(track => track?.id)}`};

        const response = JSON.parse(
            await (await newGet(url, body, {"Authorization": `Bearer ${this.token}`})).text() 
            || ""
        );

        if (!response.audio_features) return;

        const trackFeatures: spotifyTrackFeaturesType[] = [];
        for (const feature of response.audio_features) {
            trackFeatures.push(feature as spotifyTrackFeaturesType)
        }

        return trackFeatures
    }

    getDummyTrackFeatures(tracks: any[]): spotifyTrackFeaturesType[] {
        return tracks.map(track => ({
            energy: 0, 
            valence: 0,
            loudness: 0,
            danceability: 0,
            instrumentalness: 0,
        }))
    }

    async createPlaylist(tracks: spotifyTrackType[], name?: string): Promise<{name: string, spotifyUri: string} | undefined> {
        if (!name) name = generateRandomId(22);
        const url = `${API.SPOTIFY}/users/${process.env.REACT_APP_SPOTIFY_ID}/playlists`;
        const body = JSON.stringify({name: name, public: true, description: "New description"})

        const response = JSON.parse( 
            await (await newPost(url, body, {"Authorization": `Bearer ${this.token}`})).text() 
            || ""
        )

        if (!response.uri) return;
        await sleep(3000);

        const step = 100;
        const id = response.id;
        const n = tracks.length;
        console.log(`[Spotify][createPlaylist] >> adding ${n} tracks...`);

        for (let i = 0; i < n; i += step) {
            const tracksSubSet = tracks.slice(i, Math.min(i + step, n));
            try {
                await this.addTracks(id, tracksSubSet)
            } catch (error) {
                console.error(`[newSpotify][loadTracks] (error) >>`, error)
            }
        }
        
        const saved = await saveSpotifyPlaylist(response.uri, {
            tracks: tracks,
            uri: response.uri,
            name: response.name,
            cover: response.images? response.images[0]?.url || aiPlaylistCover : aiPlaylistCover,

        });
        console.log(`[newSpotify][loadPlaylist] (saved) >>`, saved);

        return {name: name, spotifyUri: response.uri}
    }

    async addTracks(id: string, tracks: spotifyTrackType[]) {
        const url = `${API.SPOTIFY}/playlists/${id}/tracks?uris=${tracks.map(track => `${track.uri}`).toString().replace(",", "%2C").replace(":", "%3A")}`;

        JSON.parse( 
            await (await newPost(url, null, {"Authorization": `Bearer ${this.token}`})).text() 
            || ""
        )
        // console.log("[Spotify][addTracks] (response) >>", response)
    }
}
